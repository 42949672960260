import FloatingActionButton from '@locus-labs/mol-mobile-floating-action-button'
import React, { useState } from 'react'
import styled, { withTheme } from 'styled-components'

const PanControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 12px;
  left: -290px;
  bottom: 20px;
`

const MapPanFab = styled(FloatingActionButton)`
  background: ${({ active, theme }) => active ? theme.colors.primaryButton : theme.colors.background};
`

const MapPanControlsMobile = withTheme(({ theme, bus, T, delta = 150 }) => {
  const [showPanControls, setShowPanControls] = useState(false)
  const onPanButtonClick = async () => {
    setShowPanControls((prev) => !prev)
  }

  const onPanUp = async () => bus.send('map/pan', { offset: [0, -1 * delta] })
  const onPanDown = async () => bus.send('map/pan', { offset: [0, delta] })
  const onPanLeft = async () => bus.send('map/pan', { offset: [-1 * delta, 0] })
  const onPanRight = async () => bus.send('map/pan', { offset: [delta, 0] })
  const onZoomIn = async () => bus.send('map/zoomIn')
  const onZoomOut = async () => bus.send('map/zoomOut')

  return <>
    <MapPanFab data-cy="MapPanFab" iconName="move.note" active={showPanControls} iconColor={showPanControls ? theme.colors.primaryButtonText : theme.colors.secondaryText} onClick={onPanButtonClick} ariaLabel={T('mapPanControls:Show Map Controls')} aria-pressed={showPanControls} aria-controls={'mapPanControls'}/>
    {showPanControls && <PanControlsWrapper id={'mapPanControls'}>
      <FloatingActionButton data-cy="mobilePanUp" iconName="arrow.up" ariaLabel={T('mapPanControls:Pan Map Up')} onClick={onPanUp}/>
      <FloatingActionButton data-cy="mobilePanDown" iconName="arrow.down" ariaLabel={T('mapPanControls:Pan Map Down')} onClick={onPanDown}/>
      <FloatingActionButton data-cy="mobilePanLeft" iconName="arrow.left" ariaLabel={T('mapPanControls:Pan Map Left')} onClick={onPanLeft}/>
      <FloatingActionButton data-cy="mobilePanRight" iconName="arrow.right" ariaLabel={T('mapPanControls:Pan Map Right')} onClick={onPanRight}/>
      <FloatingActionButton data-cy="mobileZoomIn" iconName="zoom.in" ariaLabel={T('mapPanControls:Zoom In')} onClick={onZoomIn}/>
      <FloatingActionButton data-cy="mobileZoomOut" iconName="zoom.out" ariaLabel={T('mapPanControls:Zoom Out')} onClick={onZoomOut}/>
    </PanControlsWrapper>}
  </>
})

export default MapPanControlsMobile