import * as R from 'ramda'

/**
 * Attempts to grab the user's location (bluedot, kiosk, etc.) and if none found, then
 * falls back on the map center.
 * @param {Object} bus bus for querying for location
 * @returns {Object} location ({lat, lng, floorId, ordinal, structureId }) (floorId and structureId can be null)
 */
export async function getUserLocationOrMapCenter (bus) {
  const location = await bus.send('user/getPhysicalLocation')
  if (location.length && location[0])
    return location[0]

  const position = await bus.get('map/getMapCenter')
  return position
}

/**
 * Grabs the relevant locations (ie start and end) to calculate transit times and distances for pois
 * @param {Object} bus bus for querying for locations and integrating with wayfinder module
 * @param {Array} pois array of pois objects
 * @param {Boolean} multipointBool indicates whether to use multipoint routing or not
 * */
export async function getPoisWithTransitTimes (bus, pois, previousLocation, nextLocation, multipointBool) {
  if (typeof pois === 'string' || !pois.length) return pois

  if (multipointBool) return await getMultipointPoisTransitTimes(bus, pois, previousLocation, nextLocation)
  else return await getPoisTransitTimes(bus, pois, previousLocation)
}

async function getMultipointPoisTransitTimes (bus, pois, previousLocation, nextLocation) {
  if (!previousLocation && !nextLocation) return pois
  return await bus.get('wayfinder/multipointAddPathTimeMultiple', ({ pois, startLocation: previousLocation, endLocation: nextLocation }))
}

async function getPoisTransitTimes (bus, pois, startLocation) {
  if (!startLocation.floorId) return pois
  return await bus.get('wayfinder/addPathTimeMultiple', ({ pois, startLocation }))
}

// Integration with search service  (currently searchForTerm in getDirectionsFromTo)
export async function getPoisByTerm (bus, term, previousLocation, nextLocation, multipoint) {
  if (term === 'nearby') return await getNearbyPois(bus, previousLocation, nextLocation, multipoint)
  else return bus.get('search/queryAsync', { term })
}

// replaces the searchNearby() in searchService and removes the call to wayfinder module
export async function getNearbyPois (bus, previousLocation, nextLocation, multipoint) {
  let poisSameFloor = []
  if (multipoint) poisSameFloor = await getNearbyMultipoint(bus, previousLocation, nextLocation)
  else poisSameFloor = await bus.get('poi/getByFloorId', { floorId: previousLocation?.floorId })

  return filterNearby(poisSameFloor)
}

async function getNearbyMultipoint (bus, previousLocation, nextLocation) {
  if (nextLocation?.position?.floorId !== previousLocation?.position?.floorId) {
    const list1 = await bus.get('poi/getByFloorId', { floorId: previousLocation?.position?.floorId })
    const list2 = await bus.get('poi/getByFloorId', { floorId: nextLocation?.position?.floorId })
    return R.concat(Object.values(list1), Object.values(list2))
  } else return await bus.get('poi/getByFloorId', { floorId: previousLocation?.position?.floorId })
}

function filterNearby (pois) {
  const poisArray = Object.values(pois)
  const uniquePois = R.uniqBy(R.prop('poiId'), poisArray)
  const isNotPortal = poi => poi.category.indexOf('portal') === -1 && poi.category !== 'element.door'
  const noPortalPois = Object.values(R.pickBy(isNotPortal, uniquePois))
  return noPortalPois
}

export async function checkRoutePath (bus, endpoints = []) {
  if (endpoints.length === 0)
    return { routeExists: false, hasSecurity: false, hasImmigration: false }

  const { routeExists, hasSecurity, hasImmigration } = await R.aperture(2, endpoints).reduce(async (promiseAcc, value) => {
    const routeCheck = await bus.get('wayfinder/checkIfPathHasSecurity', { fromEndpoint: value[0], toEndpoint: value[1] })

    const acc = await promiseAcc

    acc.routeExists &&= routeCheck.routeExists
    acc.hasSecurity ||= routeCheck.hasSecurity
    acc.hasImmigration ||= routeCheck.hasImmigration

    return acc
  }, { routeExists: true, hasSecurity: false, hasImmigration: false })

  return {
    routeExists,
    hasSecurity,
    hasImmigration
  }
}

export async function getDirectAlternateRoutes (bus, endpoints = [], options = {}) {
  const { directRoutes, alternativeRoutes } = await R.aperture(2, endpoints).reduce(async (promiseAcc, value) => {
    const directRoute = await bus.get('wayfinder/getRoute', { fromEndpoint: value[0], toEndpoint: value[1], options })
    const alternativeRoute = await bus.get('wayfinder/getRoute', { fromEndpoint: value[0], toEndpoint: value[1], options: { ...options, requiresAccessibility: true } })

    const acc = await promiseAcc

    acc.directRoutes = acc.directRoutes.concat(directRoute)
    acc.alternativeRoutes = acc.alternativeRoutes.concat(alternativeRoute)

    return acc
  }, { directRoutes: [], alternativeRoutes: [] })

  return {
    directRoutes,
    alternativeRoutes
  }
}

// an endpoint is considered "defined" if it has either a `chosenPoi` object defined or a `location` object
export const endPointDefined = R.either(R.prop('chosenPoi'), R.prop('location'))
// for multipoint routing we don't just need the 'from' and 'to' to be defined but every search input box should have a defined value
export const allEndPointsDefined = (searchInputs) =>
  Boolean(
    searchInputs &&
        Object.keys(searchInputs).every((key) =>
          endPointDefined(searchInputs[key])
        ) &&
        Object.keys(searchInputs).length >= 2
  )
export const definedEndpointCount = (searchInputs) =>
  Object.keys(searchInputs).reduce((count, key) => count + (endPointDefined(searchInputs[key]) ? 1 : 0), 0)
