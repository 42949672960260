/**
 * Attempts to grab the user's location (bluedot, kiosk, etc.) and if none found, then
 * falls back on the map center.
 * @param {Object} bus bus for querying for location
 * @returns {Object} location ({lat, lng, floorId, ordinal, structureId }) (floorId and structureId can be null)
 */
async function getLocation (bus) {
  const location = await bus.send('user/getPhysicalLocation')
  if (location.length && location[0])
    return location[0]

  const position = await bus.get('map/getMapCenter')
  return position
}

export { getLocation }
