import React from 'react'

import { widgetEnum } from '../../../../utils/constants.js'

import MapPanControlsDesktop from './MapPanControlsDesktop.jsx'
import MapPanControlsMobile from './MapPanControlsMobile.jsx'

const MAP_PAN_CONTROLS_DESKTOP = 'mapPanControlsDesktop'
const MAP_PAN_CONTROLS_MOBILE = 'mapPanControlsMobile'
/** Pixels for each pan offset */
const PAN_DELTA = 150

const createDesktopController = async (app) => {
  app.bus.send('layers/register', {
    id: MAP_PAN_CONTROLS_DESKTOP,
    widget: () => (
      <MapPanControlsDesktop bus={app.bus} T={app.gt()} delta={PAN_DELTA} />
    ),
    show: true,
    isOverlay: true,
    layoutId: 'buttonOptions',
    widgetType: widgetEnum.Desktop
  })
}

const createMobileController = async (app) => {
  app.bus.send('layers/register', {
    id: MAP_PAN_CONTROLS_MOBILE,
    widget: () => (
      <MapPanControlsMobile bus={app.bus} T={app.gt()} delta={PAN_DELTA} />
    ),
    shouldShow: currentlyShowing => {
      if (currentlyShowing.includes('SearchResultsBody') && currentlyShowing.includes('SearchResultsHeader')) return true
      const widgetsToCheck = ['SearchResultsHeader', 'DirectionsSearchControls', 'DirectionsResultControls', 'DirectionsResultMobile', 'poiViewMobile', 'SearchResultsCategoryList']
      return !widgetsToCheck.some(widget => currentlyShowing.includes(widget))
    },
    isOverlay: true,
    layoutId: 'mapControls',
    widgetType: widgetEnum.Mobile
  })
}

function create (app, config) {
  const init = () => {}

  if (!config.hide) {
    createDesktopController(app)
    createMobileController(app)
  }

  return {
    init
  }
}

export { create }
