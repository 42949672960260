import React from 'react'
import styled from 'styled-components'

import { CircleIcon } from '../../../../../src/ui/icons/CircleIcon.jsx'
import ToolTipComponent from '../../../../../src/ui/tooltips/ToolTipComponent.jsx'

const TightToolTip = styled(ToolTipComponent)`
  width: fit-content;
`

const Wrapper = styled.div`
  margin-top: 20%;
`

const AddStopButton = styled.button`
  margin: 18px 0 0 0;
  display: flex;
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
  background: none;
  border: none;
  opacity: ${( props ) => (props['aria-disabled'] ? '0.5' : '1')};
  padding-left: 12px;
  cursor: ${( props ) => (props['aria-disabled'] ? 'not-allowed' : 'pointer')};
  & svg {
    cursor: ${(props) => (props['aria-disabled'] ? 'not-allowed' : 'pointer')};
  }
`

const StyledAddStopText = styled.div`
  margin: auto;
  padding-left: 10px;
`

const AddWaypointButton = ({ disabled, tooltipText, handleAddStop, theme, T }) => (
  <TightToolTip tooltipText={tooltipText} arrowPosition={'left'} isVisible={disabled}>
    <Wrapper>
      <AddStopButton
        tabIndex="0"
        data-cy="AddStop"
        onClick={(...args) => !disabled && handleAddStop(...args)}
        aria-disabled={disabled}
        aria-label={disabled ? tooltipText : T('getDirectionsFromTo:Add stop')}>
          <CircleIcon
            id="add"
            backgroundColor={theme?.colors?.primaryButton || 'blue'}
            size={20}
            fillColor={theme?.colors?.primaryButtonText || 'white'} />
        <StyledAddStopText disabled={disabled} data-cy="AddStopText">{T('getDirectionsFromTo:Add stop')}</StyledAddStopText>
      </AddStopButton>
    </Wrapper>
  </TightToolTip>
)

export default AddWaypointButton
