import React from 'react'

import { StateObjMultiple } from '../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../utils/constants.js'

import MapCompass from './mapCompass.jsx'

const MAP_COMPASS_ID = 'mapCompass'
const DEFAULT_BEARING = 0

function create (app) {
  const init = () => { }

  const widgetState = new StateObjMultiple({
    bearing: DEFAULT_BEARING
  })
  const handleMapResetButtonClick = () => app.bus.send('map/resetBearing')
  app.bus.send('layers/register', {
    id: MAP_COMPASS_ID,
    widget: () => <MapCompass
      isDesktop={app.env.isDesktop()}
      handleMapResetButtonClick={handleMapResetButtonClick}
      widgetState={widgetState}
      T={app.gt()} />,
    show: true,
    isOverlay: true,
    layoutId: 'buttonOptions',
    widgetType: widgetEnum.Desktop
  })

  app.bus.on('map/userMoving', ({ bearing }) => {
    widgetState.update({ bearing })
  })

  app.bus.on('map/moveEnd', ({ bearing }) => {
    widgetState.update({ bearing })
  })

  return {
    init
  }
}

export {
  create
}
