import React from 'react'
import styled from 'styled-components'

import AtriusLogoSVG from '../../../src/images/atrius-logo-standard.svg'

import CopyrightInfo from './CopyrightInfo.js'
import VersionInfo from './VersionInfo.js'

// z-index higher than map z-index (50)
const Position = styled.footer`
  position: absolute;
  z-index: 51;
  bottom: ${({ centered }) => centered ? '8px' : '12px'};
  left: ${({ centered }) => centered ? '50%' : '14px'};
  transform: ${({ centered }) => centered ? 'translate(-50%, 0)' : 'none'};

  .-mobile & {
    left: 5px;
    bottom: 11px;
  }
`

const AppInfoButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: #333;
  text-shadow: -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff, 1px 1px 1px #fff;
  opacity: 0.6;
  height: 24px;
`

const Watermark = styled.div`
  margin: -2px 2px 0 0;
  background: url(${AtriusLogoSVG}) no-repeat center center;
  background-size: contain;
  width: 58px;
  height: 14px;
`

const AppInfo = ({ app, onClick }) => {
  const text = `© Map Data ${new Date().getFullYear().toString()}`
  return (
    <AppInfoButton data-cy='AppInfo' onClick={onClick} dir="ltr" aria-label={` Atrius ${text} ${app.info.wePkg.version}`}>
      <Watermark />
      {!app.env.isMobile() && <CopyrightInfo text={text}/>}
      <VersionInfo app={app} ></VersionInfo>
    </AppInfoButton>
  )
}

function create (app, config) {
  const init = () => { }

  const centered = config.centered || false
  const T = app.gt()
  app.bus.send('layers/register', {
    id: 'appInfo',
    widget: () => (
      <Position
        role="contentinfo"
        aria-label={T('ui:Map information')}
        centered={centered}>
        <AppInfo
          app={app}
          onClick={() => app.bus.send('appInfo/watermarkClicked')}
        />
      </Position>),
    shouldShow: currentlyShowing => {
      if (!app.env.isMobile()) return true
      return currentlyShowing.includes('headerOnlineHome')
    },
    isOverlay: true,
    layoutId: 'appInfo'
  })

  return {
    init
  }
}

export {
  AppInfo,
  create
}
