import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Icon } from '../../../../src/ui/icons/Icon.jsx'
import { withModalWindow } from '../../../../src/ui/modals/ModalWindowMol.jsx'
import { isAppleMobile } from '../../../../src/utils/dom.js'
import { calcOnce } from '../../../../src/utils/funcs.js'
import { StateObjMultiple } from '../../../../src/utils/stateObj.js'
import { widgetEnum } from '../../../../utils/constants.js'

import createMarker from './bluedotMarker.js'
import FollowMeButton from './FollowMeButton.js'
import SimLocationProvider, { initDebugTools } from './SimLocationProvider.js'
import WebGeoLocationProvider from './WebGeoLocationProvider.js'

const OkButton = styled.button`
  padding: 1em;
  background: ${({ theme }) => theme.colors.primaryButtonHover};
  color: ${({ theme }) => theme.colors.primaryButtonText};
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  border: none;
  &:focus-visible {
    outline: 2px solid orange;
    outline-offset: 2px;
  }
`
const Centered = styled.div`
  display: flex;
  justify-content: center;
`

function create (app, config) {
  const LocationServicesDisabledDialog = withModalWindow(
    ({ T }) => <>
      <Centered><Icon id="route-unavailable" /></Centered>
      <h2>{T('bluedot:Location Services Disabled')}</h2>
      <p>
        {T('bluedot:Please enable location services')}
      </p>
      <OkButton data-cy='OkCloseBluedot' onClick={closeDialog}>
        {T('bluedot:Ok')}
      </OkButton>
    </>, '[data-cy=OkCloseBluedot]')

  const LocationServicesNotAvailableDialog = withModalWindow(
    ({ T }) => <>
      <Centered><Icon id="route-unavailable" /></Centered>
      <h2>{T('bluedot:Location Services Not Available')}</h2>
      <p>
        {T('bluedot:services not yet available in building')}
      </p>
      <OkButton data-cy='OkCloseBluedot' onClick={closeDialog}>
        {T('bluedot:Ok')}
      </OkButton>
    </>, '[data-cy=OkCloseBluedot]')

  const widgetState = new StateObjMultiple({ isFollowing: false, isMonitoring: false, hasLocation: false })

  const log = app.log.sublog('bluedot')

  // begin by defaulting to no support - once venue data is loaded, we will check if it has clFloor info (Core Location)
  let venueSupportsBluedot = false // Once venue data is loaded, set this appropriately
  const deviceSupportsBluedot = navigator.geolocation && isAppleMobile() // currently only support devices that support CoreLocation

  let locationProvider
  let marker

  // when venue data loads, make note of the bounds and structures - if a new venue is loaded, this will udpate as well
  app.bus.on('venueData/mapDataLoaded', async ({ structures, venueBounds }) => {
    // venueSupportsBluedot = Object.values(structures[0].levels)[0].clfloor !== undefined
    venueSupportsBluedot = await app.bus.get('venueData/getPositioningSupported').then(ps => ps && ps.includes && ps.includes('CoreLocation'))

    if (config.notifyIfUnavailable && deviceSupportsBluedot && !venueSupportsBluedot && !config.forcebluedot)
      return displayLocationServicesUnavailable()

    const isMonitoring = (venueSupportsBluedot && deviceSupportsBluedot) || config.forcebluedot
    widgetState.update({ isFollowing: isMonitoring, isMonitoring })

    if (!isMonitoring) return

    marker = createMarker(app, log, structures, venueBounds, widgetState)
    try {
      if (locationProvider)
        locationProvider.stop()
      locationProvider = new WebGeoLocationProvider()
      locationProvider.start(marker.updatePos, displayPermissionsDialog)
    } catch (e) {
      log.error(e)
      log.info('Stopping all bluedot activity')
      widgetState.update({ isFollowing: false, isMonitoring: false, hasLocation: false })
    }
  })

  initDebugTools(app)

  const displayPermissionsDialog = calcOnce(displayPermissionsDialogActual) // don't keep bugging user
  function displayPermissionsDialogActual () {
    if (config.notifyIfDisabled ?? true) {
      const T = app.gt()
      widgetState.update({
        altDialog: <LocationServicesDisabledDialog T={T}/>
      })
    }
  }

  function displayLocationServicesUnavailable () {
    const T = app.gt()
    widgetState.update({
      altDialog: <LocationServicesNotAvailableDialog T={T}/>
    })
  }

  function closeDialog () {
    widgetState.update({ altDialog: null })
  }

  const toggleIsFollowing = () => {
    widgetState.update({ isFollowing: !widgetState.getState().isFollowing })
    if (marker)
      marker.refresh()
  }

  app.bus.send('layers/register', {
    id: 'bluedot/followMeButton',
    widget: () => config.forcebluedot || (venueSupportsBluedot && deviceSupportsBluedot)
      ? <FollowMeButton
        widgetState={widgetState}
        onClick={toggleIsFollowing}
      />
      : null,
    layoutId: 'mapControls',
    isOverlay: true,
    widgetType: widgetEnum.Mobile,
    shouldShow: currentlyShowing => {
      if (app.config.uiHide && app.config.uiHide.controls) return false
      if (currentlyShowing.includes('SearchResultsBody') && currentlyShowing.includes('SearchResultsHeader')) return true
      const widgetsToCheck = ['SearchResultsHeader', 'DirectionsSearchControls', 'DirectionsResultControls', 'DirectionsResultMobile', 'poiViewMobile', 'SearchResultsCategoryList']
      return !widgetsToCheck.some(widget => currentlyShowing.includes(widget))
    }
  })

  app.bus.send('layers/register', {
    id: 'bluedot/altDialog',
    widget: memo(() => {
      const [altDialog, setAltDialog] = useState(widgetState.getState().altDialog)
      useEffect(() => {
        widgetState.addCallback(state => { if (altDialog !== state.altDialog) setAltDialog(state.altDialog) })
      }, [])
      return altDialog || null
    }),
    layoutId: 'dialog',
    isOverlay: true,
    show: true,
    widgetType: widgetEnum.Mobile
  })

  const stopFollowing = () => {
    if (widgetState.getState().isFollowing) {
      widgetState.update({ isFollowing: false })
      if (marker)
        marker.refresh()
    }
  }

  app.bus.on('mapLevelSelector/selectLevel', stopFollowing)
  app.bus.on('bluedot/stopFollowing', stopFollowing)

  app.bus.on('map/userMoveStart', state => {
    /*
    NOTE: had to abort use of onInteractionStateChange - it wasn't setting these variables (so state is undefined) - maybe some day.
      interactionState.inTransition (Boolean)
      interactionState.isDragging (Boolean)
      interactionState.isPanning (Boolean)
      interactionState.isRotating (Boolean)
      interactionState.isZooming (Boolean)
    */
    // if (state.isPanning || state.isZooming)
    stopFollowing()
  })
  app.bus.monitor('navigation/showDirectionsFromTo', ({ endpointIds: [from] }) => {
    if (!from || !from.isPhysicalLocation)
      stopFollowing()
  })

  // items seperated by :
  // lat:lng:clFloor:accuracy (last 1 optional - clFloor if undefined mimics not inside or floor not known)
  const geo = term => { // i.e. 37.783926:-122.407130:1
    const fields = term.split(':')
    const lat = parseFloat(fields[0])
    const lng = parseFloat(fields[1])
    const clFloor = fields.length > 2 ? parseFloat(fields[2]) : undefined
    const acc = fields.length > 3 ? parseInt(fields[3]) : 30 // default to 30 meters
    log.info(`manual bluedot at ${lat},${lng} on floor ${clFloor} with accuracy ${acc}`)
    marker.updatePos(
      Date.now(),
      lat,
      lng,
      acc,
      clFloor,
      'Simulator')
  }

  const launchSim = (script, speed) => {
    locationProvider.stop()
    locationProvider = new SimLocationProvider(script, speed)
    locationProvider.start(marker.updatePos)
  }

  app.bus.on('bluedot/geo', ({ term }) => geo(term))
  app.bus.on('bluedot/sim', ({ script, speed }) => launchSim(script, speed))

  if (app.debug) {
    window.geo = geo
    window.sim = launchSim
  }

  /**
   * @return {Endpoint} - user location endpoint
   */
  app.bus.on('user/getPhysicalLocation', async () => {
    const T = app.gt()
    if (marker) {
      const lastReading = marker.getLastReading()
      if (lastReading && widgetState.getState().hasLocation) {
        const { latitude, longitude, ordinal, structureId } = lastReading
        const floorId = await getFloorId(lastReading)
        return {
          title: T('bluedot:My Location'),
          lat: latitude,
          lng: longitude,
          floorId,
          ordinal,
          isPhysicalLocation: true,
          structureId
        }
      }
    }
  })

  const getFloorId = async lastReading => {
    const floorAtCurrentLocation = () => app.bus.get('map/getFloorAt', lastReading).then(floor => floor && floor.id)
    const floorAtMapCenter = () => app.bus.get('map/getMapCenter').then(center => center.floorId)

    return Promise.resolve(lastReading.floorId)
      .then(floorId => floorId || floorAtCurrentLocation())
      .then(floorId => floorId || floorAtMapCenter())
  }

  app.bus.on('homeview/performConfirmedSearch', ({ term, script }) => {
    term = term.toLowerCase()

    if (term === 'debug:bluedotsim')
      launchSim()

    if (term === 'debug:bluedotsimstop') {
      locationProvider.stop()
      locationProvider = new WebGeoLocationProvider()
      locationProvider.start(marker.updatePos)
    }
    if (term.startsWith('debug:geo:'))
      geo(term.substring(10))
  })

  return {
    getState: widgetState.getState,
    init: () => { }, // nothing to do...
    internal: {
      getFloorId
    }
  }
}

export {
  create
}
